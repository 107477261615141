import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DetailComponent } from '@app/common/templates/detail/detail.component';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: '../../../../common/templates/detail/detail.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/detail/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblGefaessSperreDetailComponent extends DetailComponent {
	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public messageService: MessageService,
		public router: Router,
		public translate: TranslateService,
	) {
		super(accountService, breadcrumbService, confirmationService, crudService, messageService, router, translate);

		this.apiUrl = 'TblGefaessSperre';
		this.name = 'MENU.SPERRE';
		this.url = '/' + PATH.GEFAESS_SPERRE;
		this.fieldgroups.push(
			[
				//{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
				//{ type: 'text', key: 'ankey', required: true, width: 400 },
				//{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
				//{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
				{ type: 'text', key: 'identcode_sperre', required: false, width: 400 },
				{ type: 'text', key: 'sperrgrund', required: false, width: 400 },
				{ type: 'date', key: 'sperre_von', required: false, width: 400 },
				{ type: 'date', key: 'sperre_bis', required: false, width: 400 },
				{ type: 'numeric', key: 'sperre_flags', required: false, width: 400 },
				{ type: 'boolean', key: 'SYNTH_typ_graue_liste', required: false, width: 400 },
			]
		);
		this.breadcrumbService.setItems([
			{ label: 'MENU.BHV' },
			{ label: 'MENU.GEFAESS', routerLink: ['/' + PATH.GEFAESS] },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}
}
