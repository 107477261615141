import { Base } from "./base";

export enum CollectionSetTypes {
	ALL_RELEVANT_ENTRIES = 0,
	ALL_RELEVANT_FOR_BILLING = 9
}

export interface OldCollection {
	ds_auto_id?: number;
	ds_this_id?: number;
	_seTimestamp?: Date;
	se_vehicle_ankey?: string;
	se_driver_ankey?: string;
	se_worker1_ankey?: string;
	se_worker2_ankey?: string;
	se_waste_ankey?: string;
	se_tour_ankey?: string;
	se_signature?: number;
	be_event?: number;
	gps_longitude?: number;
	gps_latitude?: number;
	gps_satellites?: number;
	gps_velocity?: number;
	gps_course?: number;
	gps_altitude?: number;
	gps_longitude2?: number;
	gps_latitude2?: number;
	gps_meter?: number;
	gps_pdop?: number;
	lrg_barcode?: string;
	lrg_gefaessnr?: number;
	lrg_status?: number;
	lrg_quelle?: number;
	lrg_wiegeschein?: number;
	lrg_error?: number;
	lrg_brutto?: number;
	lrg_netto?: number;
	lrg_info?: number;
}

export interface Collection extends Base {
	f_jdzeit?: number;
	f_zeitpunkt?: Date;
	f_systemsignatur?: string;
	f_barcode?: string;
	f_brutto?: number;
	f_tara?: number;
	f_netto?: number;
	f_wiegeschein?: number;
	f_quelle?: number;
	f_status?: number;
	f_tour?: string;
	f_tourkennung?: string;
	f_fraktion?: string;
	f_revier?: string;
	f_fahrer?: string;
	f_fahrzeug?: string;
	f_longitude?: number;
	f_latitude?: number;
	f_satellites?: number;
	f_heading?: number;
	f_speed?: number;
	f_altitude?: number;
	f_datensatztyp?: number;
	f_infoflag?: number;
	f_zyklusnr?: number;
	f_zykluszeitms?: number;
	gefaess_nr?: number;
	gefaess_id?: number;
	fraktion_id?: number;
	volumen_id?: number;
	objekt_id?: number;
	link_gefobj_id?: number;
	link_objadr_id?: number;
	importdatei_id?: number;
	tour_id?: number;
	datei_id?: number;
	entsorger_id?: number;
	bericht_id?: number;
	revier_id?: number;
	fahrzeug_id?: number;
	eigentuemer_id?: number;
	status?: number;
	clearing?: number;
	virtualparentid?: number;
	virtualparentmode?: number;
	virtualid?: number;
	virtualmode?: number;
	importflag?: number;
	kalenderwoche?: number;
	wochentag?: number;
	standort?: string;
	strasse_id?: number;
	ort_id?: number;
	f_jddatum?: number;
	f_jduhrzeit?: number;
	f_scused?: number;
	f_infoflag2?: number;
	secguard?: string;
	secdata?
	bdata?
	bsize?: number;
	cnt_gefaess_id?: number;
	cnt_link_objekt_gefaess_id?: number;
	cnt_objekt_id?: number;
	cnt_link_kunde_objekt_id?: number;
	cnt_kunde_id?: number;
	cnt_auftrag_id?: number;
	cnt_abschnitt_id?: number;
	vdms?: number;
	abschnitt_id?: number;
	auftrag_id?: number;
	benutzer?: number;
	freitext?: string;
	geo_plz?: number;
	geo_ort?: string;
	geo_ortsteil?: string;
	geo_strasse?: string;
	geo_hausnummer?: string;
	gebiet_id?: number;
	rkey?: number;
	ds_valid_from?: Date;
	ds_valid_to?: Date;
	anfahrt_s?: number;
	anfahrt_t?: number;
	abfahrt_s?: number;
	abfahrt_t?: number;
	verbleib_s?: number;
	verbleib_t?: number;
	strecke_s?: number;
	strecke_t?: number;
	dateiname?: string;
	f_gebiet?: string;
	f_volumen?: string;
	se_timestamp?: string;
	se_vehicle_ankey?: string;
	se_driver_ankey?: string;
	se_worker1_ankey?: string;
	se_worker2_ankey?: string;
	se_waste_ankey?: string;
	se_tour_ankey?: string;
	se_signature?: number;
	be_event?: number;
	gps_longitude?: number;
	gps_latitude?: number;
	gps_satellites?: number;
	gps_velocity?: number;
	gps_course?: number;
	gps_altitude?: number;
	gps_longitude2?: number;
	gps_latitude2?: number;
	gps_meter?: number;
	gps_pdop?: number;
	lrg_barcode?: string;
	lrg_gefaessnr?: number;
	lrg_status?: number;
	lrg_quelle?: number;
	lrg_wiegeschein?: number;
	lrg_error?: number;
	lrg_brutto?: number;
	lrg_netto?: number;
	lrg_info?: number;

	FREMD_abfallart_ankey?: string;
	FREMD_abfallart_bezeichnung?: string;

	FREMD_abfallarttyp_ankey?: string;
	FREMD_abfallarttyp_bezeichnung?: string;
	FREMD_abfallarttyp_bezeichnung_kurz?: string;

	FREMD_volumen_ankey?: string;
	FREMD_volumen_bezeichnung?: string;

	FREMD_status_ankey?: string;
	FREMD_status_bezeichnung?: string;

	FREMD_fahrzeug_ankey?: string;
	FREMD_fahrzeug_bezeichnung?: string;
	FREMD_fahrzeug_kennzeichen?: string;
	FREMD_fahrzeug_logbox_serial?: string;
	FREMD_gefaess_barcode?: string;
	FREMD_objekt_ankey?: string;
	FREMD_tour_bezeichnung?: string;
	FREMD_entsorger_bezeichnung?: string;
	FREMD_eigentuemer_bezeichnung?: string;
	FREMD_strasse_bezeichnung?: string;
	FREMD_ort_bezeichnung?: string;
	FREMD_gebiet_bezeichnung?: string;

	readonly VIRTUAL_flagresult_LRG: boolean;
	readonly VIRTUAL_flagresult_ABR: boolean;
}

export interface Volume extends Base {
	wert?: number;
}

