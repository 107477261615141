import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '@app/common/global_variables';
import { ListComponent } from '@app/common/templates/list/list.component';
import { Base } from '@app/models/base';
import { Module, Operation } from '@app/models/permission';
import { AccountService } from '@app/services/account.service';
import { CRUDService } from '@app/services/crud.service';
import { ExportService } from '@app/services/export.service';
import { SettingsService } from '@app/services/settings.service';
import PATH from '@assets/routes/routes.json';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Policy } from '@app/models/policy';

@Component({
	templateUrl: './list.component.html',
	styleUrls: ['../style.scss', '../../../../common/templates/list/style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class TblRueckfahrkatasterAkteListComponent extends ListComponent {
	apiUrlAufzeichnung: string = '';
	contextMenuRecords: MenuItem[]; // Kontextmenu der Aufzeichnungen in der Tabelle
	url_aufzeichnung: string = ''; // Url des Stammdatentyps nach dem # 
	url_strecke: string = '';
	draggedFile: Base;
	draggedRecord: Base;
	editGrouping: boolean;
	loadingRecords: number;
	ratingOptions: any;
	ratings: any;
	private transferLimit: number = 10;
	private visyAdmin: boolean = false;

	constructor(
		public accountService: AccountService,
		public breadcrumbService: BreadcrumbService,
		public changeDetectorRef: ChangeDetectorRef,
		public confirmationService: ConfirmationService,
		public crudService: CRUDService,
		public elRef: ElementRef,
		public exportService: ExportService,
		public globals: Globals,
		public messageService: MessageService,
		public router: Router,
		public settingsService: SettingsService,
		public translate: TranslateService
	) {
		super(accountService, breadcrumbService, changeDetectorRef, confirmationService, crudService, elRef, exportService, globals, messageService, router, settingsService, translate);

		this.apiUrl = 'TblRueckfahrkatasterAkte';
		this.name = 'MENU.RUECKFAHRKATASTER';
		this.url = '/' + PATH.RK_STRECKE;
		this.url_aufzeichnung = '/' + PATH.RK_AUFZEICHNUNG;
		this.url_strecke = '/' + PATH.RK_STRECKE;
		this.apiUrlAufzeichnung = 'TblRueckfahrkatasterAufzeichnung';
		this.module = Module.ReversingCadastral;

		this.possibleCols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung', required: true, width: 400 },
			{ type: 'text', key: 'bezeichnung_kurz', required: false, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'boolean', key: 'in_kartaster', required: true, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'rating', key: 'bewertung', required: false, width: 400 },
			{ type: 'text', key: 'rk_gruende', required: false, width: 400 },
			{ type: 'text', key: 'rk_gefahren', required: false, width: 400 },
			{ type: 'text', key: 'rk_massnahmen', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 }
		];
		this.cols = [
			{ type: 'numeric', key: 'lfd_nummer', required: true, width: 100 },
			{ type: 'text', key: 'FREMD_strecke_ankey', required: true, width: 400 },
			{ type: 'text', key: 'name', required: true, width: 400 },
			{ type: 'numeric', key: 'plz_von', required: false, width: 400 },
			{ type: 'text', key: 'ort_von', required: false, width: 400 },
			{ type: 'text', key: 'strasse_von', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_von', required: false, width: 400 },
			{ type: 'numeric', key: 'plz_bis', required: false, width: 400 },
			{ type: 'text', key: 'ort_bis', required: false, width: 400 },
			{ type: 'text', key: 'strasse_bis', required: false, width: 400 },
			{ type: 'text', key: 'hausnummer_bis', required: false, width: 400 },
			{ type: 'numeric', key: 'laenge', required: true, width: 400 },
			{ type: 'boolean', key: 'breiter_350', required: true, width: 400 },
			{ type: 'boolean', key: 'kuerzer_150', required: true, width: 400 },
			{ type: 'text', key: 'freitext', required: true, width: 400 },
			{ type: 'rating', key: 'bewertung', required: false, width: 400 },
			{ type: 'text', key: 'rk_gruende', required: false, width: 400 },
			{ type: 'text', key: 'rk_gefahren', required: false, width: 400 },
			{ type: 'text', key: 'rk_massnahmen', required: false, width: 400 },
			{ type: 'text', key: 'rk_status', required: false, width: 400 }
		];

		this.breadcrumbService.setItems([
			{ label: 'MENU.RUECKFAHRKATASTER' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.LIST', routerLink: [this.url + '/' + PATH.LIST] }
		]);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.translate.get('init').subscribe((text: string) => {
			this.contextMenuRecords = [
				{ label: this.translate.instant('CONTEXT_MENU.CREATE_FILE_FROM_RECORD'), icon: 'pi pi-fw pi-plus', command: () => this.createFileAndTrack(this.selectedEntry) },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN'), icon: 'pi pi-fw pi-search', command: () => this.detailRecord() },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_TAB'), icon: 'pi pi-fw pi-search', command: () => this.detailRecord('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.OPEN_WINDOW'), icon: 'pi pi-fw pi-search', command: () => this.detailRecord('window') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT'), icon: 'pi pi-fw pi-pencil', command: () => this.editRecord() },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_TAB'), icon: 'pi pi-fw pi-pencil', command: () => this.editRecord('tab') },
				{ label: this.translate.instant('CONTEXT_MENU.EDIT_WINDOW'), icon: 'pi pi-fw pi-pencil', command: () => this.editRecord('window') },
				{ label: this.translate.instant('CONTEXT_MENU.DELETE'), icon: 'pi pi-fw pi-trash', command: () => this.deleteRecord() },
				{ label: this.translate.instant('CONTEXT_MENU.RESIZE'), icon: 'pi pi-fw pi-arrows-h', command: () => this.resizeTableWidthFromContent(true) }
			];
			this.possibleCols.forEach(c => {
				c.label = this.translate.instant('HEADERS.' + c.key);
			});
		});

		this.ratingOptions = [
			{ label: '0 von 5', value: 0, icon: '' },
			{ label: '1 von 5', value: 1, icon: 'green' },
			{ label: '2 von 5', value: 2, icon: 'lightgreen' },
			{ label: '3 von 5', value: 3, icon: 'yellow' },
			{ label: '4 von 5', value: 4, icon: 'lightcoral' },
			{ label: '5 von 5', value: 5, icon: 'red' },
		];

		this.ratings =
		{
			0: '',
			1: 'green',
			2: 'lightgreen',
			3: 'yellow',
			4: 'lightcoral',
			5: 'red'
		};

		this.visyAdmin = this.isAdmin();
	}

	isAdmin(): boolean {
		return this.accountService.isAuthorized(Policy.ViSyAdmins);
	}

	customAfterViewInit(): void {
		this.table.expandedRowKeys = {};
	}

	trackByFunction = (index, item) => {
		return item.ds_auto_id;
	}

	/**
	 * Aus der Datenbank geladene Einträge für die Verwendung vorbereiten
	 * 
	 * @param entries Aus der Datenbank geladenen Einträge
	 */
	processEntries(entries: any): void {
		this.loading += 1;
		this.loadTimestamp = new Date();
		if (!this.persistenceCheckInterval) {
			this.persistenceCheckInterval = setInterval(() => {
				this.crudService.checkPersistence(this.apiUrl, this.loadTimestamp).then(isPersistent => {
					if (!isPersistent) {
						this.messageService.add({ key: 'refresh', severity: 'warn', summary: this.translate.instant('BUTTONS.REFRESH'), detail: this.translate.instant('MESSAGES.NEW_DATA_AVAILABLE') });
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				});
			}, 1000 * 60 * 10);
		}
		this.possibleCols.forEach(c => {
			if (c.type == 'date') {
				entries.forEach(e => {
					if (e[c.key] != null) {
						e[c.key] = new Date(e[c.key]);
					}
				});
			}
		});
		this.entries = [...entries];
		this.count = this.entries.length;
		this.storageSetEntries();
		this.loading -= 1;
	}

	toggleEntry(expanded, entry) {
		if (expanded && !entry.aufzeichnungen) {
			this.loadRecords(entry);
		}
	}

	loadRecords(entry): void {
		this.loading += 1;
		this.loadingRecords = entry.ds_this_id;
		const filter = {
			strasse: null,
			ort: null,
			plz: null,
			in_kartaster: null,
			gefahren: [],
			massnahmen: [],
			gruende: [],
			status: [],
			fahrzeuge: [],
			datum_von: null,
			datum_bis: null,
			ras_deaktiviert: null,
			laenger_als: null,
			akte_id: entry.ds_this_id
		}
		this.crudService.getFilteredEntries('TblRueckfahrkatasterAufzeichnung', filter).then(res => {
			if (!entry.aufzeichnungen) {
				entry.aufzeichnungen = [];
			}
			this.loading += 1;
			let startIndex = 0;
			const maxEntries = 1500;
			const bufferSize = 100;
			const interval = setInterval(() => {
				let buffer = res.slice(startIndex, startIndex + bufferSize);
				buffer.forEach((value, index) => {
					entry.aufzeichnungen[startIndex + index] = value;
				});
				startIndex = startIndex + bufferSize;
				if (startIndex > res.length) {
					buffer = res.slice(startIndex);
					buffer.forEach((value, index) => {
						entry.aufzeichnungen[startIndex + index] = value;
					});
					clearInterval(interval);
					this.loading -= 1;
				} else {
					if (startIndex > maxEntries) {
						this.messageService.add({ severity: 'warn', summary: 'Max. Einträge erreicht', detail: 'Es wurden zu viele Einträge für diese Akte geladen. Um alle Aufzeichnungen zu dieser Akte zu sehen, gehen Sie bitte auf die Detail-Seite der Akte.', life: 30000 });
						clearInterval(interval);
						this.loading -= 1;
					}
				}
			}, 100);
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loadingRecords = null;
			this.loading -= 1;
		});
	}

	groupTracks() {
		this.confirmationService.confirm({
			message: 'Durch das automatische Gruppieren werden alle unsortierten Strecken einer Akte zugeordnet. Dieser Vorgang kann einige Minuten in Anspruch nehmen. Automatische Gruppierung ausführen?',
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				if (this.accountService.checkPermissions(Module.ReversingCadastral, Operation.UPDATE)) {
					this.crudService.groupTracks().then(res => {
						this.getAllEntries();
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: 'Strecken erfolgreich gruppiert!', life: 3000 });
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					this.loading -= 1;
				}
			}
		});
	}

	transferFiles() {
		this.confirmationService.confirm({
			message: 'Durch diese Admin-Funktion werden die Video-/GPX-Daten aus den hinterlegten Dateien in die Datenbank eingespeist. Anwendung nur auf Datensätze ohne Binärdaten. Ende wenn Limit erreicht, alle wenn Limit = 0. Jetzt anstoßen?',
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				if (this.accountService.checkPermissions(Module.ReversingCadastral, Operation.UPDATE)) {
					this.crudService.transferFilesToDbInTracks(this.transferLimit).then(res => {
						this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: res, life: 3000 });
					}).catch(err => {
						err.error.forEach(e => {
							if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
							} else {
								this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
							}
						})
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					this.loading -= 1;
				}
			}
		});
	}

	dragEnd(event) {
		this.draggedRecord = null;
		this.draggedFile = null;
	}

	dragStart(event, file, record?) {
		this.draggedRecord = record;
		this.draggedFile = file;
	}

	drop(event, targetFile) {
		// Wurde das Element auf sich selbst gedroppt
		if (this.draggedFile != targetFile) {
			// Wurde das Element auf einem anderen Eintrag oder auf der Kopfzeile gedroppt
			if (targetFile) {
				// Wurde eine Aufzeichnung oder eine Akte gedroppt
				if (this.draggedRecord) {
					// Aufzeichnung in die Zielakte verschieben
					this.moveRecord(this.draggedRecord, this.draggedFile, targetFile);
				} else {
					// Alle Aufzeichnungen der gedroppten Akte in Zielakte verschieben
					if (this.draggedFile['aufzeichnungen']) {
						this.draggedFile['aufzeichnungen'].forEach(record => {
							this.moveRecord(record, this.draggedFile, targetFile);
						});
					}
				}
			} else {
				// Wurde eine Aufzeichnung oder eine Akte gedroppt
				if (this.draggedRecord) {
					// Wenn eine Aufzeichnung auf die Kopfzeile gedroppt wird, wird eine neue Akte für mit dieser Aufzeichnung angelegt
					this.createFileAndTrack(this.draggedRecord);
				} else {
					// Wenn eine Akte auf die Kopfzeile gedroppt wird, werden alle Aufzeichnungen in die Default Akte geschoben
					if (this.draggedFile['aufzeichnungen']) {
						targetFile = this.entries.find(x => x.ds_this_id == 0);
						this.draggedFile['aufzeichnungen'].forEach(record => {
							this.moveRecord(record, this.draggedFile, targetFile);
						});
					}
				}
			}
			this.entries = [...this.entries];
		}
	}

	// Aufzeichnungen in eine andere Akte verschieben
	moveRecord(record, oldFile, newFile) {
		this.loading += 1;
		const oldIndex = oldFile.aufzeichnungen.indexOf(record);
		record.akte_id = newFile.ds_this_id;
		this.crudService.editEntry(this.apiUrlAufzeichnung, record).then(res => {
			record.lfd_nummer = (newFile.aufzeichnungen && newFile.aufzeichnungen.length > 0) ? Math.max(...newFile.aufzeichnungen.map(x => x.lfd_nummer)) + 1 : 1;
			newFile.aufzeichnungen.push(record);
			oldFile.aufzeichnungen.splice(oldIndex, 1);
			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
		}).catch(err => {
			record.akte_id = oldFile.ds_this_id;
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// Neue Akte und Strecke anlegen und Aufzeichnung in diese Akte verschieben
	createFileAndTrack(record) {
		this.loading += 1;
		this.crudService.createFileAndTrack(record).then(resCreateFileAndRec => {
			record.lfd_nummer = 1;
			const oldFile = this.entries.find(x => x.ds_this_id == record.akte_id);
			const oldIndex = oldFile['aufzeichnungen'].indexOf(record);
			record.akte_id = resCreateFileAndRec;
			this.loading += 1;
			this.crudService.getEntry(this.apiUrl, resCreateFileAndRec).then(res => {
				res.lfd_nummer = this.entries.length;
				this.entries[this.entries.length - 1].lfd_nummer = this.entries.length + 1;
				this.entries.splice(this.entries.length - 1, 0, res);
				res['aufzeichnungen'] = [record];
				oldFile['aufzeichnungen'].splice(oldIndex, 1);
				this.processEntries(this.entries);
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
			this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #region ContextMenu

	isAufzeichnungSelected(): boolean {
		if (this.selectedEntry && this.selectedEntry['Tablename']) {
			return this.selectedEntry['Tablename'] == 'tbl_rueckfahrkataster_aufzeichnung';
		} else {
			return false;
		}
	}

	/**
	 * Löscht den Eintrag mit der angegebenen ds_this_id aus der Datenbank 
	 * 
	 * @param ds_this_id Id des zu löschenden Eintrags
	 */
	deleteRecord(ds_this_id?: number): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.crudService.deleteEntry(this.apiUrlAufzeichnung, ds_this_id ? ds_this_id : this.selectedEntry.ds_this_id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					const entry = this.entries.find(x => x.ds_this_id == this.selectedEntry['akte_id']);
					if (entry) {
						this.loadRecords(entry);
					}
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	/**
	 * Öffnet die Detail-Seite für den ausgewählten Eintrag abhängig von target in einem neuen Fenster, in einem neuen Tab oder im selben Tab
	 * 
	 * @param target 'window' für öffnen in neuen Fenster, 'tab' für öffnen in neuem Tab, null oder alles andere für öffnen im selben Tab
	 */
	detailRecord(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url_aufzeichnung + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url_aufzeichnung + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url_aufzeichnung + '/' + PATH.DETAIL + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	/**
	 * Öffnet die Bearbeiten-Seite für den ausgewählten Eintrag abhängig von target in einem neuen Fenster, in einem neuen Tab oder im selben Tab
	 * 
	 * @param target 'window' für öffnen in neuen Fenster, 'tab' für öffnen in neuem Tab, null oder alles andere für öffnen im selben Tab
	 */
	editRecord(target?: string): void {
		if (target == 'window') {
			window.open('/#/' + this.url_aufzeichnung + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id, '_blank', 'newWindow=1');
		} else if (target == 'tab') {
			window.open('/#/' + this.url_aufzeichnung + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id);
		} else {
			this.router.navigate([this.url_aufzeichnung + '/' + PATH.EDIT + '/' + this.selectedEntry.ds_this_id]);
		}
	}

	// #endregion ContextMenu
}
